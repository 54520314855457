import axios from 'axios'
import { Message } from 'element-ui';
import { Loading } from 'element-ui';
import router from '../router/index.js';

//创建axios实例
const instance = axios.create()
var loadingInstance; 

//添加请求拦截器( 请求发送到服务器之前拦截请求 )
instance.interceptors.request.use(function (config) {
    // config.headers.Authorization ="Bearer " +  localStorage.getItem("token");
    if (localStorage.getItem('token')) { 
        // console.log(localStorage.getItem('token'));
        //在请求头加入token，名字要和后端接收请求头的token名字一样 
        config.headers.token=localStorage.getItem('token');  
    }
    // console.log(localStorage.getItem("token"));
    //显示loading动画
    loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })

    return config;
}, function (error) {

    return Promise.reject(error);
});

//添加响应拦截器( 请求到达客户端浏览器之前拦截响应 )
instance.interceptors.response.use(function (response) {
    
    //隐藏loading动画
    loadingInstance.close();
    // console.log(response);
    // console.log(response.headers);
    if( response.headers.token == ''){ //这个条件成立,说明登陆成功
        //保存token到本地存储
        localStorage.setItem("token",response.data.data.token);
    }
    if( response.data.code == 605){ //这个条件成立,说明登陆成功
        //保存token到本地存储
        localStorage.removeItem("token");
        //弹出提示框
        Message({ message:"身份验证失败,登陆过期",type:"error" })
        //主动重定向到登录页
        router.push('/login')
    }
    if( response.data.code == 607){ //这个条件成立,说明用户不存在或密码错误
        //弹出提示框
        Message({ message:"用户不存在或密码错误",type:"error" })
    }
    if( response.data.code == 608){ 
        //弹出提示框
        Message({ message:response.data.msg,type:"error" })
    }
    return response;
}, function (error) {
    //隐藏loading动画
    loadingInstance.close();
    // if(error.response.data.data.code !== ''){
    //     if(error.response.data.data.code == 8001){
    //         Message({ message:response.data.data.msg,type:"error" })
    //     }
    // }
    //判断响应状态码
    if( error.response.status == 401 ){ //身份验证失败
        //移除localStorage中的token信息
        localStorage.removeItem("token");
        //弹出提示框
        Message({ message:"身份验证失败,登陆过期",type:"error" })
        //主动重定向到登录页
        router.push('/login')
    }else if( error.response.status == 404 ){ //访问路径有误
        //弹出提示框
        Message({ message:"访问路径有误",type:"error" })
    }else if( error.response.status == 500 ){ //服务器内部错误
        //弹出提示框"服务端较忙"
        Message({ message:"服务器内部错误",type:"error" })
    }
    return Promise.reject(error);
});
export default instance;