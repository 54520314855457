import instance from './request'

function login(params){ //登录
    return instance.post(`/admin/login/loginUser`,params)
}
function controllist(params){ //控制台 列表
    return instance({
        url: "/Admin/Console/getUserAndVisitTable",
        method: "GET",
        params: params
    });
}
function updateMsgStatic(params){ //控制台微信通知
    return instance.get(`/Admin/Console/updateMsgStatic/${params.isStatic}`,params)
}
function getVisitsList(data){ //接访列表
    return instance({
        url: "/Admin/Visits/getVisitsList",
        method: "post",
        data: data
    });
}
function getOneVisits(params){ //接访详情
    return instance.get(`/Admin/Visits/getOneVisits/${params.visitid}`,params)
}
function getMessage(params){ //新闻资讯，常见问题，通知通告
    return instance.get(`/Admin/AdminMessage/getMessage/${params.type}`,params)
}
function getRoleList(params){ //角色管理
    return instance.get(`/Admin/AdminUser/getRoleList/${params.Limit}/${params.page}`,params)
}
function getRoleById(params){ //角色对应权限
    return instance.get(`/Admin/AdminUser/getRoleById/${params.roleId}`,params)
}
function getAdminRoleAll(params){ //角色全部 权限
    return instance.get(`/Admin/AdminDictAllShow/getAdminRoleAll`,params)
}
function getMissCountCity(params){ //角色可以操作的院
    return instance.get(`/Admin/AdminUser/getMissCountCity/${params.roleId}`,params)
}
function delAdminAddRole(params){ //删除角色
    return instance.get(`/Admin/AdminUser/delAdminAddRole/${params.reloId}`,params)
}
function AdminAddRole(params){ //提交
    return instance.post(`/Admin/AdminUser/AdminAddRole`,params)
}
function getAdminUserAll(params){ //用户管理
    return instance.get(`/Admin/AdminUser/getAdminUserAll`,params)
}
function delAdminUser(params){ // 删除用户
    return instance.get(`/Admin/AdminUser/delAdminUser/${params.userId}`,params)
}
function getAdminUserOne(params){ //编辑用户
    return instance.get(`/Admin/AdminUser/getAdminUserOne/${params.userId}`,params)
}
function addAdminUser(params){ //保存用户
    return instance.post(`/Admin/AdminUser/addAdminUser`,params)
}
function getAdminRoleAllList(params){ //用户类型
    return instance.get(`/Admin/AdminDictAllShow/getAdminRoleAllList`,params)
}
function updateAdminUserState(params){ //用户状态
    return instance.get(`/Admin/AdminUser/updateAdminUserState/${params.userId}/${params.state}`,params)
}
function getAllOrganization(params){ //组织架构
    return instance.get(`/Admin/AdminUser/getAllOrganization`,params)
}
function getOneOrganization(params){ //组织架构编辑
    return instance.get(`/Admin/AdminUser/getOneOrganization/${params.proId}`,params)
}
function getFproname(params){ //组织架构编辑
    return instance.get(`/Admin/AdminUser/getFproname/${params.fid}`,params)
}
function getOneCountry(params){ //组织架构获取院
    return instance.get(`/Admin/AdminUser/getOneCountry`,params)
}
function addOrganization(data){   //组织架构保存
    return instance({
        url: "/Admin/AdminUser/addOrganization",
        method: "post",
        data: data
    });
}
function getAllDict(params){ //组织架构获取院
    return instance.get(`/Admin/AdminUser/getAllDict`,params)
}
function getMessageOne(params){ //资讯管理所有详情页
    return instance.get(`/Admin/AdminMessage/getMessageOne/${params.messageId}`,params)
}
function addMessageOne(data){   //组织架构保存
    return instance({
        url: "/Admin/AdminMessage/addMessageOne",
        method: "post",
        data: data
    });
}
function getUserCountAllList(params){ //资讯管理院
    return instance.get(`/Admin/AdminDictAllShow/getUserCountAllList`,params)
}
function delMessageOne(params){ //资讯管理删除
    return instance.get(`/Admin/AdminMessage/delMessageOne/${params.msgId}`,params)
}
function getAdminAboutMy(params){ //资讯管理所有详情页
    return instance.get(`/Admin/AdminMessage/getAdminAboutMy/${params.type}`,params)
}
function addAdminAboutMy(data){   //组织架构保存
    return instance({
        url: "/Admin/AdminMessage/addAdminAboutMy",
        method: "post",
        data: data
    });
}
function getUserTypes(params){ //检察院用户管理下拉框
    return instance.get(`/Admin/AdminDictAllShow/getUserTypes`,params)
}
function getUserAll(data){   //检察院用户管理l列表
    return instance({
        url: "/Admin/AfterUser/getUserAll",
        method: "post",
        data: data
    });
}
function delUser(params){ //检察院用户管理删除
    return instance.get(`/Admin/AfterUser/delUser/${params.userId}`,params)
}
function getUserOne(params){ //检察院用户管理删除
    return instance.get(`/Admin/AfterUser/getUserOne/${params.userId}`,params)
}
function updateUserState(params){ //检察院用户管理状态
    return instance.get(`/Admin/AfterUser/updateUserState/${params.userId}/${params.staticId}`,params)
}
function addUserOne(data){   //检察院用户管理l列表
    return instance({
        url: "/Admin/AfterUser/addUserOne",
        method: "post",
        data: data
    });
}
function getchartList(data){ //统计列表
    return instance({
        url: "/Admin/Charts/getVisitsList",
        method: "post",
        data: data
    });
}
function getAdminMyphotoAll(data){ //统计图表
    return instance({
        url: "/Admin/Charts/getAdminMyphotoAll",
        method: "post",
        data: data
    });
}
function getMsgOne(params){ //控制台微信信息回显
    return instance.get(`/Admin/Console/getMsgOne/${params.msgId}`,params)
}
function updateMsgOne(data){ //控制台微信信息提交
    return instance({
        url: "/Admin/Console/updateMsgOne",
        method: "post",
        data: data
    });
}
function updateMsg(params){ //控制台微信信息回显
    return instance.get(`/Admin/Console/updateMsgOne/${params.userId}/${params.switchmsgId}`,params)
}
function getStaticAll(params){ //状态字典
    return instance.get(`/Admin/AdminDictAllShow/getStaticAll`,params)
}
function getDocuAll(params){ //案件类型字典
    return instance.get(`/Admin/AdminDictAllShow/getDocuAll`,params)
}
function getRoomVisit(params){ //倒计时
    return instance.get(`/Admin/Visits/getRoomVisit/${params.visitId}`,params)
}
function addVisitRecording(data){ //进入房间
    return instance({
        url: "/Admin/Visits/addVisitRecording",
        method: "post",
        data: data
    });
}
function endVisit(params){ //结束接访
    return instance.get(`/Admin/Visits/endVisit/${params.visitId}`,params)
}
function getOneVisitRecording(params){ //结束接访
    return instance.get(`/Admin/Visits/getOneVisitRecording/${params.visitId}`,params)
}
function updatePassword(data){ //修改密码
    return instance({
        url: "/admin/login/updatePassword",
        method: "post",
        data: data
    });
}
export {
    login,
    controllist,
    updateMsgStatic,
    getVisitsList,
    getOneVisits,
    getMessage,
    getRoleList,
    getRoleById,
    getMissCountCity,
    getAdminRoleAll,
    AdminAddRole,
    getAdminUserAll,
    delAdminUser,
    getAdminUserOne,
    addAdminUser,
    getAdminRoleAllList,
    updateAdminUserState,
    getAllOrganization,
    getOneOrganization,
    getFproname,
    getOneCountry,
    addOrganization,
    getAllDict,
    getMessageOne,
    addMessageOne,
    getUserCountAllList,
    delMessageOne,
    getAdminAboutMy,
    addAdminAboutMy,
    getUserTypes,
    getUserAll,
    delUser,
    getUserOne,
    updateUserState,
    addUserOne,
    getchartList,
    getMsgOne,
    updateMsgOne,
    updateMsg,
    getAdminMyphotoAll,
    getStaticAll,
    getDocuAll,
    getRoomVisit,
    addVisitRecording,
    endVisit,
    getOneVisitRecording,
    delAdminAddRole,
    updatePassword
}