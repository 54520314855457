import Vue from 'vue'
import Vuex from 'vuex'
import Logger from 'vuex/dist/logger'
Vue.use(Vuex)
import {login} from '../utils/api'
import filterRoutes from '../utils/filterRoutes'
import routes from '../router/routes'
import vuexpersistedstate from 'vuex-persistedstate'
export default new Vuex.Store({
  state: { //负责存储最终的数据
    userinfo:'',
    username:'',
    roles:[],//用户权限列表
  },
  mutations: { //定义同步函数
    save_userinfo(state,item){ //保存用户信息
      state.userinfo = item;
    },
    save_username(state,item){ //保存用户信息
      state.username = item;
    },
    save_roles(state,item){ //保存用户权限列表
      state.roles = item;
    },
  },
  actions: { //定义异步函数
    request_login({commit},item){ //发起登陆请求
      return new Promise((resolve,reject)=>{
        
        login(item).then((res)=>{
          // console.log(res);
          if( res.data.code == 200 ){
            resolve(res);//成功
            //保存用户信息到 vuex
            commit('save_userinfo',res.data.data.admin);
            commit('save_username',res.data.data.username);
            //保存用户权限列表到 vuex
            commit('save_roles',res.data.data.role);
            // console.log(routes);
          }
          else{
            reject(res);
          }
        })
      })
    },
  },
  getters:{
    get_routes(state){ //得到过滤之后的 当前用户的路由表
      // console.log(routes);
      return  filterRoutes( routes, state.roles );
    }
  },
  modules: {
  },
  plugins:[Logger(),vuexpersistedstate()]
})