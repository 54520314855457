const routes = [
  {
    path: '/index/console',
    name: 'console',
    meta:{ title:"控制台",role:"Console" },
    component: () => import('../views/Index/Console.vue'),
  },
  {
    path: '/index/exit',
    name: 'exit',
    meta:{ title:"远程接访",role:"Visits" },
    component: () => import('../views/Index/Exit.vue'),
    children:[
      {
        path: '/order-list',
        name: 'order-list',
        meta:{ title:"接访记录",role:"index3" },
        component: () => import('../views/Index/Order/Order.vue')
      }
    ]
  },
  {
    path: '/user',
    name: 'usersall',
    meta:{ title:"检察院用户管理",role:"SuperUser" },
    redirect: '/user/users',
    component: () => import('../views/Index/Exit.vue'),
    children:[
      {
        path: '/user/users',
        meta:{ title:"用户管理",role:"index3" },
        component: () => import('../views/Index/User/Users.vue')
      }
    ]
  },
  {
    path: '/information',
    name: 'information',
    meta:{ title:"资讯管理",role:"Message" },
    redirect: '/information/inform',
    component: () => import('../views/Index/Exit.vue'),
    children:[
      {
        path: '/information/inform',
        name: 'inform',
        meta:{ title:"通知公告",role:"inform" },
        component: () => import('../views/Index/Information/Inform.vue')
      },
      {
        path: '/information/news',
        name: 'news',
        meta:{ title:"新闻资讯",role:"news" },
        component: () => import('../views/Index/Information/News.vue')
      },
      {
        path: '/information/problem',
        name: 'problem',
        meta:{ title:"常见问题",role:"problem" },
        component: () => import('../views/Index/Information/Problem.vue')
      },
      {
        path: '/information/about',
        name: 'about',
        meta:{ title:"关于我们",role:"about" },
        component: () => import('../views/Index/Information/About.vue')
      },
      {
        path: '/information/subscribe',
        name: 'subscribe',
        meta:{ title:"预约须知",role:"subscribe" },
        component: () => import('../views/Index/Information/Subscribe.vue')
      },
    ]
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta:{ title:"统计查询",role:"Charts" },
    redirect: '/statistics/statisticslist',
    component: () => import('../views/Index/Exit.vue'),
    children:[
      {
        path: '/statistics/statisticstable',
        name: 'statisticstable',
        meta:{ title:"查询统计",role:"statisticstable" },
        component: () => import('../views/Index/Statistics/Statisticstable.vue')
      },
      {
        path: '/statistics/statisticslist',
        name: 'statisticslist',
        meta:{ title:"查询列表",role:"statisticslist" },
        component: () => import('../views/Index/Statistics/Statisticslist.vue')
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    meta:{ title:"系统管理",role:"Admin" },
    redirect: '/system/organization',
    component: () => import('../views/Index/Exit.vue'),
    children:[
      {
        path: '/system/organization',
        name: 'organization',
        meta:{ title:"组织架构",role:"organization" },
        component: () => import('../views/Index/System/Organization.vue')
      },
      {
        path: '/system/role',
        name: 'role',
        meta:{ title:"角色管理",role:"role" },
        component: () => import('../views/Index/System/Rolelist.vue')
      },
      {
        path: '/system/userlist',
        name: 'userlist',
        meta:{ title:"用户管理",role:"userlist" },
        component: () => import('../views/Index/System/Userlist.vue')
      },
      {
        path: '/system/dictionaries',
        name: 'dictionaries',
        meta:{ title:"字典管理",role:"dictionaries" },
        component: () => import('../views/Index/System/Dictionaries.vue')
      },
    ]
  }
]
export default routes;