//判断用户有没有 当前路由的访问权限( true/false )
//参数一: roles 用户的权限列表
//参数二: route 需要判断的路由对象
function hasPermission(roles,route){
    if( route.meta && route.meta.role ){ //条件成立 说明当前路由route需要权限
        // return ['home','index1','index2'].includes( 'index1' )
        return roles.includes( route.meta.role )
    }else{ //条件不成立 说明当前路由route不需要权限
        return true;
    }
}

//根据用户的权限列表, 从完整的路由表中过滤 当前用户有权限访问的路由
//参数一: allRoutes 完整的路由表
//参数二: roles 用户的权限列表
function filterRoutes( allRoutes, roles ){
    var canAccessedRoutes = allRoutes.filter((route)=>{
        if( hasPermission(roles,route) ){ // 条件成立, 说明 有权限
            // if( route.children && route.children.length > 0 ){ //条件成立 说明有子路由
            //     //过滤子路由
            //     route.children = filterRoutes( route.children, roles )
            // }
            return true;
        }else{
            return false;
        }
    })
    return canAccessedRoutes;
}

export default filterRoutes;
