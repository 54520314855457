import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/index',
    // name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue'),
    children:[
      {
        path: '/index/console',
        name: 'console',
        meta:{ title:"控制台",role:"console" },
        component: () => import('../views/Index/Console.vue'),
      },
      {
        path: '/index/password',
        name: 'password',
        meta:{ title:"修改密码",role:"password" },
        component: () => import('../views/Index/Password.vue'),
      },
      {
        path: '/index/exit',
        name: 'exit',
        meta:{ title:"远程接访",role:"exit" },
        component: () => import('../views/Index/Exit.vue'),
        children:[
          {
            path: '/order-list',
            name: 'order-list',
            meta:{ title:"接访记录",role:"index3" },
            component: () => import('../views/Index/Order/Order.vue')
          }
        ]
      },
      {
        path: '/user',
        name: 'usersall',
        meta:{ title:"检察院用户管理",role:"usersall" },
        redirect: '/user/users',
        component: () => import('../views/Index/Exit.vue'),
        children:[
          {
            path: '/user/users',
            meta:{ title:"用户管理",role:"index3" },
            component: () => import('../views/Index/User/Users.vue')
          }
        ]
      },
      {
        path: '/information',
        name: 'information',
        meta:{ title:"资讯管理",role:"information" },
        redirect: '/information/inform',
        component: () => import('../views/Index/Exit.vue'),
        children:[
          {
            path: '/information/inform',
            name: 'inform',
            meta:{ title:"通知公告",role:"inform" },
            component: () => import('../views/Index/Information/Inform.vue')
          },
          {
            path: '/information/news',
            name: 'news',
            meta:{ title:"新闻资讯",role:"news" },
            component: () => import('../views/Index/Information/News.vue')
          },
          {
            path: '/information/problem',
            name: 'problem',
            meta:{ title:"常见问题",role:"problem" },
            component: () => import('../views/Index/Information/Problem.vue')
          },
          {
            path: '/information/about',
            name: 'about',
            meta:{ title:"关于我们",role:"about" },
            component: () => import('../views/Index/Information/About.vue')
          },
          {
            path: '/information/subscribe',
            name: 'subscribe',
            meta:{ title:"预约须知",role:"subscribe" },
            component: () => import('../views/Index/Information/Subscribe.vue')
          },
          {
            path: '/information/publish',
            name: 'Publish',
            meta: {
              title: "发布",
              groupName: '资讯管理'

            },
            component: () => import('../views/Index/Information/Publish.vue')
          },
        ]
      },
      {
        path: '/statistics',
        name: 'statistics',
        meta:{ title:"统计查询",role:"statistics" },
        redirect: '/statistics/statisticslist',
        component: () => import('../views/Index/Exit.vue'),
        children:[
          {
            path: '/statistics/statisticstable',
            name: 'statisticstable',
            meta:{ title:"查询统计",role:"statisticstable" },
            component: () => import('../views/Index/Statistics/Statisticstable.vue')
          },
          {
            path: '/statistics/statisticslist',
            name: 'statisticslist',
            meta:{ title:"查询列表",role:"statisticslist" },
            component: () => import('../views/Index/Statistics/Statisticslist.vue')
          }
        ]
      },
      {
        path: '/system',
        name: 'system',
        meta:{ title:"系统管理",role:"system" },
        redirect: '/system/organization',
        component: () => import('../views/Index/Exit.vue'),
        children:[
          {
            path: '/system/organization',
            name: 'organization',
            meta:{ title:"组织架构",role:"organization" },
            component: () => import('../views/Index/System/Organization.vue')
          },
          {
            path: '/system/role',
            name: 'role',
            meta:{ title:"角色管理",role:"role" },
            component: () => import('../views/Index/System/Rolelist.vue')
          },
          {
            path: '/system/userlist',
            name: 'userlist',
            meta:{ title:"用户管理",role:"userlist" },
            component: () => import('../views/Index/System/Userlist.vue')
          },
          {
            path: '/system/dictionaries',
            name: 'dictionaries',
            meta:{ title:"字典管理",role:"dictionaries" },
            component: () => import('../views/Index/System/Dictionaries.vue')
          },
        ]
      },
      {
        path: "/index",
        redirect:"/Index/console"
      }
    ]
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    // component: Home,
  },
  {
    path: '/invite',
    name: 'Invite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "invite" */ '../views/Invite.vue'),
  },
  {
    path: "/",
    redirect:"/index"
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
//全局路由守卫函数( 任何一次跳路由 都会执行 beforeEach 中的回调函数 )
router.beforeEach((to,from,next)=>{
  var token = localStorage.getItem("token");
  if( to.path == '/login' ){ //想要跳到 登录页
    if( token ){ //已登录
      router.replace('/')
    }else{ //未登录
      next();
    }
  }else{ //想要跳到 非登录页
    if( token ){ //已登录
      next();
    }else{ //未登录
      router.replace('/login')
    }
  }
})
// router.beforeEach((to, from, next) => {
//   // console.log(to);
//   if (to.name === 'console') {
//     if (localStorage.getItem('token') == null) {
//       router.replace('/login')
//     }
//   }
//   next()
// })

export default router;
