<!--
 * @Description: App.vue
 * @Date: 2022-03-21 12:16:32
 * @LastEditTime: 2022-03-21 12:21:49
-->
<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.$aegis.reportEvent({
      name: 'loaded',
      ext1: 'loaded-success',
      ext2: 'webrtcQuickDemoVue2',
    });
  },
};
</script>
<style lang="less">
.head-tit{
  // height: 52px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  .record{
    color: #56AAF7;
  }
}
// ::-webkit-scrollbar {

//   display: none; /* Chrome Safari */

// }
</style>
